import React, { FC } from "react";

import Header from "../partials/Header";
import Footer from "../partials/Footer";
import Seo from "../partials/Seo";

const Privacy: FC = () => {
  return (
    <>
      <Seo title="Terms of Use" />

      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                  <h1 className="h1 mb-4" data-aos="fade-up">
                    Terms of Service
                  </h1>
                  <p
                    className="text-xl text-gray-400"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    Effective date: 06/01/2022
                  </p>
                </div>

                <article className="mt-10 mx-auto prose prose-dark lg:prose-xl">
                  <h2>1. Introduction</h2>
                  <p>
                    Welcome to{" "}
                    <strong>Wigwam (“Company”, “we”, “our”, “us”)</strong>! As
                    you have just clicked our Terms of Service, please pause,
                    grab a cup of coffee and carefully read the following pages.
                    It will take you approximately 20 minutes.
                  </p>
                  <p>
                    These Terms of Service{" "}
                    <strong>(“Terms”, “Terms of USE”)</strong> govern your use
                    of our web pages located at https://wigwam.app/ operated by
                    Wigwam and use Wigwam Application (Web Extension).
                  </p>

                  <p>
                    <strong>Wigwam Application</strong> (“Wigwam Application ,”
                    “we,” “us,” or “our”) provides a platform for managing
                    Blockchain accounts while keeping the user in control over
                    what transactions they approve through our browser plugin
                    (the "Extension") — which includes text, images, audio, code
                    and other materials (collectively, the “Content”) and all of
                    the features, and services provided. The Extension and any
                    other features, tools, materials, or other services offered
                    from time to time by Wigwam Application are referred to here
                    as the “Services.” Please read these Terms of Service (the
                    “Terms” or “Terms of Use”) carefully before using the
                    Services. By using or otherwise accessing the Services, or
                    clicking to accept or agree to these Terms where that option
                    is made available, you (1) accept and agree to these Terms,
                    (2) consent to the collection, use, disclosure and other
                    handling of information as described in our Privacy Policy
                    and (3) agree to any additional terms, rules and conditions
                    of participation issued by Wigwam Application from time to
                    time. If you do not agree to the Terms, then you may not
                    access or use the Content or Services.
                  </p>

                  <p>
                    Our <strong>Privacy Policy</strong> also governs your use of
                    our Service and explains how we collect, safeguard and
                    disclose information that results from your use of our web
                    pages. Please read it here{" "}
                    <ExternalLink>https://wigwam.app/privacy</ExternalLink>.
                  </p>

                  <p>
                    Your agreement with us includes these Terms and our Privacy
                    Policy <strong>(“Agreements”)</strong>. You acknowledge that
                    you have read and understood Agreements, and agree to be
                    bound by them.
                  </p>

                  <p>
                    If you do not agree with (or cannot comply with) Agreements,
                    then you may not use the Service, but please let us know by
                    emailing at info@wigwam.app so we can try to find a
                    solution. These Terms apply to all visitors, users and
                    others who wish to access or use Service.
                  </p>

                  <p>Thank you for being responsible.</p>

                  <h2>2. Communications</h2>

                  <p>
                    By using our Service and leaving your email, you agree to
                    subscribe to newsletters, marketing or promotional materials
                    and other information we may send. However, you may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or by emailing at.
                  </p>

                  <h2>3. Contests, Sweepstakes and Promotions</h2>

                  <p>
                    Any contests, sweepstakes or other promotions (collectively,{" "}
                    <strong>“Promotions”</strong>) made available through
                    Service may be governed by rules that are separate from
                    these Terms of Service. If you participate in any
                    Promotions, please review the applicable rules as well as
                    our Privacy Policy. If the rules for a Promotion conflict
                    with these Terms of Service, Promotion rules will apply.
                  </p>

                  <h2>4. Fee Changes</h2>

                  <p>
                    Wigwam, in its sole discretion and at any time, may modify
                    possible fees for using our additional paid services.
                  </p>

                  <h2>5. Content</h2>

                  <p>
                    Content found on or through this Service are the property of
                    Wigwam or used with permission. You may not distribute,
                    modify, transmit, reuse, download, repost, copy, or use said
                    Content, whether in whole or in part, for commercial
                    purposes or for personal gain, without express advance
                    written permission from us.
                  </p>

                  <h2>6. Prohibited Uses</h2>

                  <p>
                    You may use Service only for lawful purposes and in
                    accordance with Terms. You agree not to use Service:
                  </p>

                  <ul>
                    <li>
                      In any way that violates any applicable national or
                      international law or regulation.
                    </li>
                    <li>
                      For the purpose of exploiting, harming, or attempting to
                      exploit or harm minors in any way by exposing them to
                      inappropriate content or otherwise.
                    </li>
                    <li>
                      To transmit, or procure the sending of, any advertising or
                      promotional material, including any “junk mail”, “chain
                      letter,” “spam,” or any other similar solicitation.
                    </li>
                    <li>
                      To impersonate or attempt to impersonate Company, a
                      Company employee, another user, or any other person or
                      entity.
                    </li>
                    <li>
                      In any way that infringes upon the rights of others, or in
                      any way is illegal, threatening, fraudulent, or harmful,
                      or in connection with any unlawful, illegal, fraudulent,
                      or harmful purpose or activity.
                    </li>
                    <li>
                      To engage in any other conduct that restricts or inhibits
                      anyone’s use or enjoyment of Service, or which, as
                      determined by us, may harm or offend Company or users of
                      Service or expose them to liability.
                    </li>
                  </ul>

                  <h2>7. Additionally, you agree not to:</h2>

                  <ul>
                    <li>
                      Use Service in any manner that could disable, overburden,
                      damage, or impair Service or interfere with any other
                      party’s use of Service, including their ability to engage
                      in real time activities through Service.
                    </li>
                    <li>
                      Use any robot, spider, or other automatic device, process,
                      or means to access Service for any purpose, including
                      monitoring or copying any of the material on Service.
                    </li>
                    <li>
                      Use any manual process to monitor or copy any of the
                      material on Service or for any other unauthorized purpose
                      without our prior written consent.
                    </li>
                    <li>
                      Use any device, software, or routine that interferes with
                      the proper working of Service.
                    </li>
                    <li>
                      Introduce any viruses, trojan horses, worms, logic bombs,
                      or other material which is malicious or technologically
                      harmful.
                    </li>
                    <li>
                      Attempt to gain unauthorized access to, interfere with,
                      damage, or disrupt any parts of Service, the server on
                      which Service is stored, or any server, computer, or
                      database connected to Service.
                    </li>
                    <li>
                      Attack Service via a denial-of-service attack or a
                      distributed denial-of-service attack.
                    </li>
                    <li>
                      Take any action that may damage or falsify Company
                      ratings.
                    </li>
                    <li>
                      Otherwise attempt to interfere with the proper working of
                      Service.
                    </li>
                  </ul>

                  <h2>8. Analytics</h2>

                  <p>
                    We may use third-party Service Providers to monitor and
                    analyze the use of our Service. For more information, read
                    our Privacy Policy section{" "}
                    <ExternalLink>https://wigwam.app/privacy</ExternalLink>.
                  </p>

                  <h2>9. No Use By Minors</h2>

                  <p>
                    Service is intended only for access and use by individuals
                    at least eighteen (18) years old. By accessing or using any
                    of the Company, you warrant and represent that you are at
                    least eighteen (18) years of age and with the full
                    authority, right, and capacity to enter into this agreement
                    and abide by all of the terms and conditions of Terms. If
                    you are not at least eighteen (18) years old, you are
                    prohibited from both the access and usage of Service.
                  </p>

                  <h2>10. Accounts</h2>

                  <p>
                    When you create an account with us, you guarantee that you
                    are above the age of 18, and that the information you
                    provide us is accurate, complete, and current at all times.
                    Inaccurate, incomplete, or obsolete information may result
                    in the immediate termination of your account on Service.
                  </p>

                  <p>
                    When setting up an account within{" "}
                    <strong>Wigwam Application</strong> , you will be
                    responsible for keeping your own account secrets, which may
                    be a twelve-word seed phrase, an account file, or other
                    locally stored secret information. Wigwam Application
                    encrypts this information locally with a password you
                    provide, that we never send to our servers. You agree to (a)
                    never use the same password for{" "}
                    <strong>Wigwam Application</strong> that you have ever used
                    outside of this service, (b) keep your secret information
                    and password confidential and do not share them with anyone
                    else and (c) immediately notify Wigwam Application of any
                    unauthorized use of your account or breach of security.
                    Wigwam Application cannot and will not be liable for any
                    loss or damage arising from your failure to comply with this
                    section.
                  </p>

                  <h2>11. Intellectual Property</h2>

                  <p>
                    Service and its original content (excluding Content provided
                    by users), features and functionality are and will remain
                    the exclusive property of Wigwam and its licensors. Service
                    is protected by copyright, trademark, and other laws of
                    foreign countries. Our trademarks and trade dress may not be
                    used in connection with any product or service without the
                    prior written consent of Wigwam.
                  </p>

                  <h2>12. Copyright Policy</h2>

                  <p>
                    We respect the intellectual property rights of others. It is
                    our policy to respond to any claim that Content posted on
                    Service infringes on the copyright or other intellectual
                    property rights (“<strong>Infringement</strong>”) of any
                    person or entity.
                  </p>

                  <p>
                    If you are a copyright owner, or authorized on behalf of
                    one, and you believe that the copyrighted work has been
                    copied in a way that constitutes copyright infringement,
                    please submit your claim via email to info@wigwam.app, with
                    the subject line: “Copyright Infringement” and include in
                    your claim a detailed description of the alleged
                    Infringement as detailed below, under “DMCA Notice and
                    Procedure for Copyright Infringement Claims”.
                  </p>

                  <p>
                    You may be held accountable for damages (including costs and
                    attorneys' fees) for misrepresentation or bad-faith claims
                    on the infringement of any Content found on and/or through
                    Service on your copyright.
                  </p>

                  <h2>
                    DMCA Notice and Procedure for Copyright Infringement Claims
                  </h2>

                  <p>
                    You may submit a notification pursuant to the Digital
                    Millennium Copyright Act (DMCA) by providing our Copyright
                    Agent with the following information in writing (see 17
                    U.S.C 512(c)(3) for further detail):
                  </p>

                  <ul>
                    <li>
                      an electronic or physical signature of the person
                      authorized to act on behalf of the owner of the
                      copyright's interest;
                    </li>
                    <li>
                      a description of the copyrighted work that you claim has
                      been infringed, including the URL (i.e., web page address)
                      of the location where the copyrighted work exists or a
                      copy of the copyrighted work;
                    </li>
                    <li>
                      identification of the URL or other specific location on
                      Service where the material that you claim is infringing is
                      located;
                    </li>
                    <li>your address, telephone number, and email address;</li>
                    <li>
                      a statement by you that you have a good faith belief that
                      the disputed use is not authorized by the copyright owner,
                      its agent, or the law;
                    </li>
                    <li>
                      a statement by you, made under penalty of perjury, that
                      the above information in your notice is accurate and that
                      you are the copyright owner or authorized to act on the
                      copyright owner's behalf.
                    </li>
                  </ul>

                  <p>
                    You can contact our Copyright Agent via email at
                    info@wigwam.app.
                  </p>

                  <h2>14. Error Reporting and Feedback</h2>

                  <p>
                    You may provide us directly at info@wigwam.app with
                    information and feedback concerning errors, suggestions for
                    improvements, ideas, problems, complaints, and other matters
                    related to our Service (“<strong>Feedback</strong>”). You
                    acknowledge and agree that: (i) you shall not retain,
                    acquire or assert any intellectual property right or other
                    right, title or interest in or to the Feedback; (ii) Company
                    may have development ideas similar to the Feedback; (iii)
                    Feedback does not contain confidential information or
                    proprietary information from you or any third party; and
                    (iv) Company is not under any obligation of confidentiality
                    with respect to the Feedback. In the event the transfer of
                    the ownership to the Feedback is not possible due to
                    applicable mandatory laws, you grant Company and its
                    affiliates an exclusive, transferable, irrevocable,
                    free-of-charge, sub-licensable, unlimited and perpetual
                    right to use (including copy, modify, create derivative
                    works, publish, distribute and commercialize) Feedback in
                    any manner and for any purpose.
                  </p>

                  <h2>15. Links To Other Web Sites</h2>

                  <p>
                    Our Service may contain links to third party web sites or
                    services that are not owned or controlled by Wigwam.
                  </p>

                  <p>
                    Wigwam has no control over, and assumes no responsibility
                    for the content, privacy policies, or practices of any third
                    party web sites or services. We do not warrant the offerings
                    of any of these entities/individuals or their websites.
                  </p>

                  <p>
                    YOU ACKNOWLEDGE AND AGREE THAT Wigwam SHALL NOT BE
                    RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
                    DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN
                    CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
                    GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD
                    PARTY WEB SITES OR SERVICES.
                  </p>

                  <p>
                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
                    PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES
                    THAT YOU VISIT.
                  </p>

                  <h2>16. Disclaimer Of Warranty</h2>

                  <p>
                    You expressly understand and agree that your use of the
                    Service is at your sole risk. The Service (including the
                    Service and the Content) are provided on an “AS IS'' and “as
                    available” basis, without warranties of any kind, either
                    express or implied, including, without limitation, implied
                    warranties of merchantability, fitness for a particular
                    purpose or non-infringement. You acknowledge that Wigwam
                    Application has no control over, and no duty to take any
                    action regarding: which users gain access to or use the
                    Service; what affects the Content may have on you; how you
                    may interpret or use the Content; or what actions you may
                    take as a result of having been exposed to the Content. You
                    release Wigwam Application from all liability for you having
                    acquired or not acquired Content through the Service. Wigwam
                    makes no representations concerning any Content contained in
                    or accessed through the Service, and Wigwam Application will
                    not be responsible or liable for the accuracy, copyright
                    compliance, legality or decency of material contained in or
                    accessed through the Service.
                  </p>

                  <p>
                    <strong>
                      You acknowledge that Wigwam Application is not responsible
                      for transferring, safeguarding, or maintaining your
                      private keys or any digital currency associated therewith.
                      If you lose, mishandle or have stolen associated digital
                      currency private keys, you acknowledge that you may not be
                      able to recover associated digital currency, and that
                      Wigwam Application is not responsible for such loss. You
                      acknowledge that Wigwam Application is not responsible for
                      any loss, damage or liability arising from your failure to
                      comply with the terms hereunder.
                    </strong>
                  </p>

                  <h2>17. Sophistication and Risk of Cryptographic Systems</h2>

                  <p>
                    By utilizing the Service or interacting with the Content or
                    platform in any way, you represent that you understand the
                    inherent risks associated with cryptographic systems; and
                    warrant that you have an understanding of the usage and
                    intricacies of native cryptographic tokens, like ETH, smart
                    contract based tokens such as those that follow the token
                    standards, and blockchain-based software systems.
                  </p>

                  <h2>
                    18. Risk of Weaknesses or Exploits in the Field of
                    Cryptography
                  </h2>

                  <p>
                    You acknowledge and understand that Cryptography is a
                    progressing field. Advances in code cracking or technical
                    advances such as the development of quantum computers may
                    present risks to cryptocurrencies and Services of Content,
                    which could result in the theft or loss of your
                    cryptographic tokens or property. To the extent possible,
                    Wigwam Application intends to update the protocol underlying
                    Services to account for any advances in cryptography and to
                    incorporate additional security measures, but does not
                    guarantee or otherwise represent full security of the
                    system. By using the Service or accessing Content, you
                    acknowledge these inherent risks.
                  </p>

                  <h2>19. Volatility of Cryptocurrencies</h2>

                  <p>
                    You understand that Ethereum and other blockchain
                    technologies and associated currencies or tokens are highly
                    volatile due to many factors including but not limited to
                    adoption, speculation, technology and security risks. You
                    also acknowledge that the cost of transacting on such
                    technologies is variable and may increase at any time
                    causing impact to any activities taking place on Ethereum
                    and other blockchains. You acknowledge these risks and
                    represent that Wigwam Application cannot be held liable for
                    such fluctuations or increased costs.
                  </p>

                  <h2>20. Application Security</h2>

                  <p>
                    You acknowledge that blockchain applications are code
                    subject to flaws and acknowledge that you are solely
                    responsible for evaluating any code provided by the Services
                    or Content and the trustworthiness of any third-party
                    websites, products, smart-contracts, or Content you access
                    or use through the Service. You further expressly
                    acknowledge and represent that blockchain applications can
                    be written maliciously or negligently, that Wigwam
                    Application cannot be held liable for your interaction with
                    such applications and that such applications may cause the
                    loss of property or even identity. This warning and others
                    later provided by Wigwam Application in no way evidence or
                    represent an on-going duty to alert you to all of the
                    potential risks of utilizing the Service or Content.
                  </p>

                  <h2>21. Indemnity</h2>

                  <p>
                    You agree to release and to indemnify, defend and hold
                    harmless Wigwam and its parents, subsidiaries, affiliates
                    and agencies, as well as the officers, directors, employees,
                    shareholders and representatives of any of the foregoing
                    entities, from and against any and all losses, liabilities,
                    expenses, damages, costs (including attorneys’ fees and
                    court costs) claims or actions of any kind whatsoever
                    arising or resulting from your use of the Service, your
                    violation of these Terms of Use, and any of your acts or
                    omissions that implicate publicity rights, defamation or
                    invasion of privacy. Wigwam Application reserves the right,
                    at its own expense, to assume exclusive defense and control
                    of any matter otherwise subject to indemnification by you
                    and, in such case, you agree to cooperate with Wigwam
                    Application in the defense of such matter.
                  </p>

                  <h2>22. Limitation Of Liability</h2>

                  <p>
                    EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                    OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY
                    INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
                    DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL
                    RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR
                    AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
                    ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
                    CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING
                    OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
                    WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY
                    DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU
                    OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
                    REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF
                    THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW,
                    IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL
                    BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
                    SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
                    CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW
                    THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR
                    CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION
                    MAY NOT APPLY TO YOU.
                  </p>

                  <h2>23. Governing Law</h2>

                  <p>
                    These Terms shall be governed and construed in accordance
                    with the laws of Ukraine without regard to its conflict of
                    law provisions.
                  </p>

                  <p>
                    Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. If any
                    provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these
                    Terms will remain in effect. These Terms constitute the
                    entire agreement between us regarding our Service and
                    supersede and replace any prior agreements we might have had
                    between us regarding Service.
                  </p>

                  <h2>24. Changes To Service</h2>

                  <p>
                    We reserve the right to withdraw or amend our Service, and
                    any service or material we provide via Service, in our sole
                    discretion without notice. We will not be liable if for any
                    reason all or any part of Service is unavailable at any time
                    or for any period. From time to time, we may restrict access
                    to some parts of Service, or the entire Service, to users,
                    including registered users.
                  </p>

                  <h2>25. Amendments To Terms</h2>

                  <p>
                    We may amend Terms at any time by posting the amended terms
                    on this site. It is your responsibility to review these
                    Terms periodically.
                  </p>

                  <p>
                    Your continued use of the Platform following the posting of
                    revised Terms means that you accept and agree to the
                    changes. You are expected to check this page frequently so
                    you are aware of any changes, as they are binding on you.
                  </p>

                  <p>
                    By continuing to access or use our Service after any
                    revisions become effective, you agree to be bound by the
                    revised terms. If you do not agree to the new terms, you are
                    no longer authorized to use Service.
                  </p>

                  <h2>26. Waiver And Severability</h2>

                  <p>
                    No waiver by Company of any term or condition set forth in
                    Terms shall be deemed a further or continuing waiver of such
                    term or condition or a waiver of any other term or
                    condition, and any failure of Company to assert a right or
                    provision under Terms shall not constitute a waiver of such
                    right or provision.
                  </p>

                  <p>
                    If any provision of Terms is held by a court or other
                    tribunal of competent jurisdiction to be invalid, illegal or
                    unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the
                    remaining provisions of Terms will continue in full force
                    and effect.
                  </p>

                  <h2>27. Acknowledgement</h2>

                  <p>
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                    ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND
                    AGREE TO BE BOUND BY THEM.
                  </p>

                  <h2>28. Contact Us</h2>

                  <p>
                    Please send your feedback, comments, requests for technical
                    support:
                    <br />
                    By email: info@wigwam.app.
                  </p>
                </article>
              </div>
            </div>
          </section>
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
};

export default Privacy;

const ExternalLink: FC<{ children: string }> = ({ children }) => (
  <a href={children} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
